import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTrashAlt,
  faEyeSlash,
  faEye,
  faSquare,
  faCheckSquare,
  faFileAlt,
  faCommentDots
} from '@fortawesome/free-regular-svg-icons'
import {
  faEdit,
  faCheck,
  faTimes,
  faFilter,
  faCar,
  faClone,
  faTruckArrowRight,
  faPlus,
  faUser,
  faPhone,
  faTractor,
  faBone,
  faBoxOpen,
  faMapMarkerAlt,
  faFileExport,
  faFileInvoiceDollar,
  faDollarSign,
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
  faMinus,
  faChevronRight,
  faChevronDown,
  faSignInAlt,
  faSignOutAlt,
  faExclamationTriangle,
  faExclamationCircle,
  faExclamation,
  faBarcode,
  faCogs,
  faCog,
  faHandHoldingUsd,
  faHistory,
  faList,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faCalendarDays,
  faCaretDown,
  faBan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faEye,
  faCheck,
  faTimes,
  faFilter,
  faCar,
  faClone,
  faTruckArrowRight,
  faPlus,
  faUser,
  faPhone,
  faTractor,
  faBone,
  faBoxOpen,
  faMapMarkerAlt,
  faFileExport,
  faFileInvoiceDollar,
  faDollarSign,
  faSignInAlt,
  faSignOutAlt,
  faSortAmountDown,
  faSortAlphaUp,
  faMinus,
  faSortAlphaDown,
  faChevronRight,
  faChevronDown,
  faExclamationTriangle,
  faExclamationCircle,
  faExclamation,
  faBarcode,
  faCogs,
  faCog,
  faHandHoldingUsd,
  faHistory,
  faSquare,
  faCheckSquare,
  faFileAlt,
  faList,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faCalendarDays,
  faCaretDown,
  faBan,
  faCommentDots
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

const CUSTOM_ICONS = {
  add: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'plus']
    }
  },
  menu: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'user']
    }
  },
  list: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'list']
    }
  },
  angleDown: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'angle-down']
    }
  },
  next: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'angle-right']
    }
  },
  prev: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'angle-left']
    }
  },
  calendar: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'calendar-days']
    }
  },
  dropdown: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'caret-down']
    }
  }
}

export { CUSTOM_ICONS }
