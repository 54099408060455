<script setup>
import CommentDialog from '@/components/boning-page/CommentDialog.vue'
import draggable from 'vuedraggable'

import { defineProps, ref, watch } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { debounce } from '@/utils/common'

const DEBOUNCE_TIME = 1000

const props = defineProps(['boningList'])
const store = useStore()

const drag = ref(false)
const productList = ref(new Map())

const headers = [
  { value: 'productcode', sortable: false, text: 'Code' },
  { value: 'product_name', sortable: false, text: 'Name' },
  { value: 'quantity', sortable: false, text: 'Qty' },
  { value: 'comments', sortable: false, text: 'Comments' },
  { value: 'pieces_required', sortable: false, text: 'Pieces Required' },
  { value: 'pigs_required', sortable: false, text: 'Pigs Required' }
]

const startDrag = () => {
  drag.value = true
}

const debouncedBulkUpdate = debounce(async (payload) => {
  const resStatus = await store.dispatch('bulkUpdateBoningItems', payload)
  if (resStatus === 'error') {
    store.dispatch('fetchBoningList')
  }
}, DEBOUNCE_TIME)

const endDrag = (event, type) => {
  const { newIndex, oldIndex } = event

  const currentList = productList.value.get(type)

  const movedItem = currentList.splice(oldIndex, 1)[0]
  currentList.splice(newIndex, 0, movedItem)

  currentList.forEach((item, idx) => (item.priority = idx))

  const payload = currentList.map((item) => ({
    id: item.id,
    priority: item.priority
  }))
  debouncedBulkUpdate(payload)

  drag.value = false
}

const refreshList = () => {
  const productMap = new Map()

  props.boningList.forEach((product) => {
    if (!productMap.has(product.product_type)) {
      productMap.set(product.product_type, [])
    }
    productMap.get(product.product_type).push(product)
  })
  productList.value = productMap
}

const dragOptions = {
  animation: 200
}

watch(
  () => props.boningList,
  () => {
    refreshList()
  },
  { immediate: true, deep: false }
)
</script>

<template>
  <v-card-text>
    <v-data-table
      v-for="[type, products] in productList"
      :headers="headers"
      :items="products"
      :server-items-length="products.length"
      class="elevation-1 w-100 mb-4"
      dense
      hide-default-footer
      :key="type"
    >
      <template v-slot:top
        ><div class="text-button type-header">
          {{ type }}
        </div></template
      >
      <template #body="{ items }">
        <draggable
          :list="items"
          @start="startDrag"
          @end="($event) => endDrag($event, type)"
          tag="tbody"
          v-bind="dragOptions"
        >
          <tr v-for="element in items" :key="element.id" class="sortable-item">
            <td class="text-start" v-for="(header, idx) in headers" :key="idx">
              <CommentDialog
                v-if="header.value === 'comments'"
                :details="element"
                @updated="refreshList"
              />
              <span v-else>{{ element[header.value] }}</span>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table></v-card-text
  >
</template>

<style scoped>
.sortable-item {
  cursor: move;
}
.sortable-chosen {
  background-color: #fff9c4 !important;
}
.type-header {
  background-color: #4292d1 !important;
  color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: start;
  line-height: 2rem;
  padding-left: 1rem;
}
</style>
