<script setup>
import SideFilter from '@/components/SideFilter.vue'

import { computed } from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const store = useStore()
const filtersSupp = computed(() => store.getters.filtersSupp)

const updateFilter = (value) => {
  store.commit('SET_FILTER_SUPP', value)
  store.dispatch('fetchSuppliers')
}

const removeFilters = () => {
  store.dispatch('clearFiltersSupp')
  store.dispatch('fetchSuppliers')
}
</script>

<template>
  <SideFilter
    :filterList="filtersSupp"
    @input="updateFilter"
    @clear="removeFilters"
    class="mt-0"
  />
</template>
