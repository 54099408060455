<template>
  <b-modal id="supplier-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <label for="supplier-name"
        >Company Name:<span class="text-danger">*</span></label
      >
      <b-form-input
        id="supplier-name"
        v-model="supplier.company.name"
        type="text"
        placeholder="Enter company name"
        required
        class=""
      ></b-form-input>

      <b-row no-gutters class="align-items-baseline"
        ><label
          class="mt-3"
          v-if="supplier.company.extra.alias.length > 0"
          for="company-alias-0"
          >Name Alias:</label
        ><small
          class="pointer hover-blue"
          :class="
            supplier.company.extra.alias.length > 0 ? 'ml-auto mt-3' : 'mt-1'
          "
          @click="supplier.company.extra.alias.push('')"
          ><font-awesome-icon icon="fas fa-plus" class="mr-1" />
          <b>Add new alias</b></small
        ></b-row
      ><b-row
        v-for="(item, key) in supplier.company.extra.alias"
        :key="key"
        no-gutters
        class="justify-content-right"
        ><b-col class="mr-2">
          <b-form-input
            :id="`company-alias-${key}`"
            v-model="supplier.company.extra.alias[key]"
            type="text"
            placeholder="Enter name alias"
            class="mb-1"
          ></b-form-input
        ></b-col>
        <b-col class="col-auto"
          ><small class="pointer hover-red" @click="removeAlias(key)"
            ><b>Remove</b></small
          ></b-col
        ></b-row
      >

      <b-form-group
        id="supplier-code-group"
        label="Supplier Code:"
        label-for="supplier-code"
        class="mt-3"
      >
        <b-form-input
          id="supplier.code"
          v-model="supplier.code"
          placeholder="Enter code"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="supplier-address-group"
        label="Supplier Address:"
        label-for="supplier-address"
      >
        <b-form-input
          id="supplier-address"
          v-model="supplier.company.address.postaddress"
          placeholder="Enter address"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="supplier-phone-group"
        label="Supplier Phone:"
        label-for="supplier-phone"
      >
        <b-form-input
          id="supplier-phone"
          v-model="supplier.company.contact.phone"
          placeholder="Enter phone"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="supplier-abn-group"
        label="Supplier ABN:"
        label-for="supplier-abn"
      >
        <b-form-input
          id="supplier-abn"
          v-model="supplier.company.abn"
          placeholder="Enter ABN"
        ></b-form-input>
      </b-form-group>

      <label for="supplier-type"
        >Supplier Service Type:<span class="text-danger">*</span></label
      >
      <vue-select
        id="supplier-type"
        placeholder="Select a type ..."
        label="type"
        :options="supplierTypes"
        v-model="supplier.type.type"
        :reduce="(type) => type.type"
        class="mb-2"
        ><template #search="{ attributes, events }">
          <input
            class="vs__search"
            :required="!supplier.type.type"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </vue-select>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingSuppliers == 'create' || loadingSuppliers == 'update'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NewSupplierModal',
  props: {
    type: String
  },
  data() {
    return {
      supplier: {
        company: {
          name: '',
          address: { postaddress: '' },
          abn: '',
          contact: {
            phone: ''
          },
          extra: { alias: [] }
        },
        code: '',
        type_id: '',
        type: { type: '' }
      },
      tempInfo: null
    }
  },
  computed: {
    ...mapGetters(['loadingSuppliers', 'supplierTypes']),
    actionString() {
      if (this.type == 'add') return 'Create New Supplier'
      return 'Update Supplier Information'
    }
  },
  methods: {
    ...mapActions(['createSupplier', 'fetchSuppliers', 'updateSupplier']),
    cleanAliasArray: async function () {
      this.supplier.company.extra.alias =
        this.supplier.company.extra.alias.filter((e) => e.trim())
      return
    },
    removeAlias: function (id) {
      this.supplier.company.extra.alias.splice(id, 1)
    },
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      await this.cleanAliasArray()
      if (this.type == 'add') {
        res = await this.createSupplier(this.supplier)
      }
      if (this.type == 'edit') {
        res = await this.updateSupplier({
          payload: this.supplier,
          onSuccess: 'replace'
        })
      }
      if (res === 'error') return

      if (this.type == 'add') this.fetchSuppliers()
      this.$bvModal.hide('supplier-modal')
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: function () {
      this.resetValues()
      this.$bvModal.show('supplier-modal')
    },
    editSupplier: function (info) {
      this.tempInfo = info
      this.restoreValues()
      this.$bvModal.show('supplier-modal')
    },
    // Reset supplier form values
    resetValues: function () {
      if (Object.prototype.hasOwnProperty.call(this.supplier, 'id'))
        delete this.supplier.id
      this.supplier.company.name = ''
      this.supplier.code = ''
      this.supplier.company.address.postaddress = ''
      this.supplier.company.contact.phone = ''
      this.supplier.company.extra.alias = []
      this.supplier.company.abn = ''
      this.supplier.type.type = ''
      this.supplier.type_id = ''
    },
    // Restore supplier form values to initial
    restoreValues: function () {
      this.supplier.id = this.tempInfo.id
      this.supplier.company.abn = this.tempInfo.company.abn
      this.supplier.company.name = this.tempInfo.company.name
      this.supplier.code = this.tempInfo.code
      this.tempInfo.company.address
        ? (this.supplier.company.address.postaddress =
            this.tempInfo.company.address.postaddress)
        : (this.supplier.company.address = { postaddress: '' })

      this.tempInfo.company.contact
        ? (this.supplier.company.contact.phone =
            this.tempInfo.company.contact.phone)
        : (this.supplier.company.contact = { phone: '' })

      this.supplier.company.extra.alias = this.tempInfo.company.extra
        ? this.tempInfo.company.extra.alias
        : []
      this.supplier.type.type = this.tempInfo.type.type
      this.supplier.type_id = this.tempInfo.type_id
    }
  }
}
</script>

<style></style>
