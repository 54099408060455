<script setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Are you sure you?'
  },
  buttonText: {
    type: String,
    default: 'Confirm'
  },
  icon: {
    type: Boolean,
    default: false
  },
  text: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: ''
  },
  buttonColor: {
    type: String,
    default: 'primary'
  },
  buttonTextClass: {
    type: String,
    default: ''
  },
  buttonIcon: {
    type: String,
    default: 'mdi-check'
  }
})
defineEmits(['confirm'])

const dialog = ref(false)

const closeDialog = () => {
  dialog.value = false
}

defineExpose({ closeDialog })
</script>

<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :x-small="size === 'x-small'"
          :small="size === 'small'"
          :text="text"
          :icon="icon"
          class="my-1"
          :color="buttonColor"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          <v-icon left>{{ buttonIcon }}</v-icon
          ><span :class="buttonTextClass">{{ buttonText }}</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h6 bg-light-blue title-section">
          <slot name="title">{{ title }}</slot>
        </v-card-title>

        <v-card-text class="text-start">
          <slot name="content"
            >Click 'Yes' to continue or 'Cancel' to abort.</slot
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text small @click="closeDialog"> Cancel </v-btn>
          <v-btn color="primary" small @click="$emit('confirm')"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.title-section {
  border-bottom: 1.5px solid #4685c1;
}
</style>
