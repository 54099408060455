<script setup>
import { ref, defineProps, computed, getCurrentInstance } from 'vue'
import BaseDynamicForm from '../base/BaseDynamicForm.vue'
import { useStore } from '@/utils/VuexHelpers'
import { getCurrentDay } from '@/utils/DateHelpers'
import { formatCurrency, formatKilograms } from '@/helpers/stringHelpers'

const props = defineProps(['priceDetails', 'growerDetails'])
const vm = getCurrentInstance().proxy
const store = useStore()

const loadingSettings = computed(() => store.getters.loadingSettings)

const dialog = ref(false)
const fields = ref([
  {
    key: 'price',
    label: 'New PAR Price',
    value: props.priceDetails.value,
    type: 'number',
    min: 0,
    prefix: '$',
    step: 0.1,
    rules: [(v) => !!v || 'Price is required'],
    placeholder: 'Enter base PAR price',
    class: 'mb-2'
  },
  {
    key: 'effective_date',
    label: 'New Effective Date',
    value: getCurrentDay(),
    type: 'date',
    rules: [(v) => !!v || 'Effective date is required'],
    placeholder: 'Choose Effective date',
    class: 'mb-2'
  }
])

const handleFormSubmit = async (formData) => {
  const { effective_date, price } = formData
  const { applied_to, penaltyrules, category } = props.priceDetails

  const payload = {
    category,
    effective_date,
    value: price,
    applied_to,
    penaltyrules
  }

  const res = await store.dispatch('createParPrice', { payload, vm })
  if (res === 'success') {
    dialog.value = false
    store.dispatch('fetchGrowerParPrice', {
      growerId: String(props.growerDetails.company_id)
    })
  }
}
</script>

<template>
  <div>
    <v-btn @click.stop="dialog = true" color="info" small text>
      <font-awesome-icon :icon="['fas', 'clone']" class="me-1" />
      <span class="text-decoration-underline">Duplicate</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-text class="pt-4">
          <h6 class="text-button text-start text-light-blue">
            {{ `Duplicate PAR price - ${growerDetails.code}` }}
          </h6>

          <BaseDynamicForm
            :formFields="fields"
            :resetOnInputChange="true"
            :loading="loadingSettings === 'par-price'"
            @submit="handleFormSubmit"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <h6 class="text-button text-start text-light-blue">Details</h6>
          <div class="text-start">
            <span>Company: </span
            ><span class="font-weight-bold">{{
              growerDetails.company.name
            }}</span>
          </div>
          <div class="text-start mb-4">
            <span>Category: </span
            ><span class="font-weight-bold">{{
              `${priceDetails.category}`
            }}</span>
          </div>
          <div class="text-start">Penalty Rates:</div>
          <div class="grid-container">
            <div><span class="font-weight-bold">P2</span> (start - end)</div>
            <div>
              <span class="font-weight-bold">Weight</span> (start - end)
            </div>
            <div class="font-weight-bold">Penalty</div>
            <div class="divider"></div>
            <template v-for="(rate, idx) in priceDetails.penaltyrules">
              <div :key="`${idx}-p2-${rate.p2.start}`">
                {{
                  `${rate.p2.start} - ${rate.p2.end > 30 ? 'MAX' : rate.p2.end}`
                }}
              </div>
              <div :key="`${idx}-weight-${rate.weight.start}`">
                {{
                  `${formatKilograms(rate.weight.start)} - ${
                    rate.weight.end > 105
                      ? 'MAX'
                      : formatKilograms(rate.weight.end)
                  }`
                }}
              </div>
              <div :key="`${idx}-price-${rate.value}`">
                {{ `${formatCurrency(rate.value)}` }}
              </div>
              <div :key="`${idx}-divider`" class="divider"></div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  gap: 8px;
  width: 100%;
}

.grid-container > div {
  text-align: start;
  margin-right: 2rem;
}

.divider {
  grid-column: 1 / -1; /* Span the divider across all columns */
  height: 1px;
  background-color: #ccc;
}
</style>
