<script setup>
import SideFilter from '@/components/SideFilter.vue'
import { computed } from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const store = useStore()
const filtersProd = computed(() => store.getters.filtersProd)

const updateFilter = (value) => {
  store.commit('SET_FILTER_PROD', value)
  store.dispatch('fetchProducts')
}
const removeFilters = () => {
  store.dispatch('clearFiltersProd')
  store.dispatch('fetchProducts')
}
</script>

<template>
  <SideFilter
    :filterList="filtersProd"
    @input="updateFilter"
    @clear="removeFilters"
    class="mt-0"
  />
</template>
