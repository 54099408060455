<script setup>
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm.vue'
import { formatDate } from '@/helpers/stringHelpers'

import { useStore } from '@/utils/VuexHelpers'
import { computed, defineProps } from 'vue'

const props = defineProps(['growerDetails', 'priceDetails'])
const store = useStore()

const loadingSettings = computed(() => store.getters.loadingSettings)

const deletePARPrice = async () => {
  const res = await store.dispatch('deleteParPrice', {
    payload: props.priceDetails
  })
  if (res === 'success') {
    store.dispatch('fetchGrowerParPrice', {
      growerId: String(props.growerDetails.company_id)
    })
  }
}
</script>
<template>
  <BaseDeleteConfirm
    :icon="true"
    size="small"
    title="Are you sure you want to delete PAR Price?"
    :loading="loadingSettings === 'par-price-delete'"
    class="me-1"
    @deleteConfirm="deletePARPrice"
  >
    <template #content>
      <div class="text-subtitle-2 mt-2">
        Grower:
        <span class="font-weight-bold">
          {{ growerDetails.company.name }}
        </span>
      </div>
      <div class="text-subtitle-2 mt-2">
        Effective Date:
        <span class="font-weight-bold">
          {{ formatDate(priceDetails.effective_date) }}
        </span>
      </div>
      <div class="text-subtitle-2 mt-2">
        Applied To:
        <span class="font-weight-bold">
          {{
            priceDetails.applied_to === 'Default' ? 'All' : growerDetails.code
          }}
        </span>
      </div>
    </template>
  </BaseDeleteConfirm>
</template>
