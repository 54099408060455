<script setup>
import BaseAlert from '@/components/base/BaseAlert.vue'
import SuppliersFilter from '@/components/suppliers-page/SuppliersFilter.vue'
import PaginationBase from '../components/PaginationBase.vue'
import iconDollar from '../components/iconDollar.vue'
import IconEdit from '../components/IconEdit.vue'
import GrowerPricing from '@/components/suppliers-page/GrowerPricing.vue'
import FreightCharges from '@/components/suppliers-page/FreightCharges.vue'
import NewSupplierModal from '../components/NewSupplierModal.vue'

import { computed, ref } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { SUPPLIERS } from '@/constants/pagination'

const suppModal = ref(null)

const store = useStore()
const suppliers = computed(() => store.getters.suppliers)
const freightCharges = computed(() => store.getters.freightCharges)
const totalSuppliers = computed(() => store.getters.totalSuppliers)
const customerNames = computed(() => store.getters.customerNames)
const loadingSuppliers = computed(() => store.getters.loadingSuppliers)
const loadingCustomers = computed(() => store.getters.loadingCustomers)
const ascSupp = computed(() => store.getters.ascSupp)
const pageNumberSupp = computed(() => store.getters.pageNumberSupp)
const perPageOptions = computed(() => store.getters.perPageOptions)
const perPageSuppliers = computed(() => store.getters.perPageSuppliers)
const sortbySupp = computed(() => store.getters.sortbySupp)

const modalAction = ref('add')
const expanded = ref([])

const headers = [
  { value: 'name', text: 'Company Name', sortable: true },
  { value: 'code', text: 'Code', sortable: true },
  { value: 'address', text: 'Address', sortable: false },
  { value: 'phone', text: 'Phone', sortable: false },
  { value: 'abn', text: 'ABN', sortable: false },
  { value: 'type', text: 'Service Type', sortable: false },
  { value: 'data-table-expand', text: 'Actions', sortable: false }
]
const sortChange = ({ sortBy, sortDesc }) => {
  if (sortBy[0] !== sortbySupp.value) {
    store.commit('SET_SORT_BY_SUPP', sortBy[0])
  }
  if (sortDesc[0] === ascSupp.value) {
    store.commit('SET_ASC_SUPP', !sortDesc[0])
  }
  store.dispatch('fetchSuppliers')
}

const fetchCharges = (row) => {
  const { isExpanded, expand, item } = row

  expand(!isExpanded)
  if (!freightCharges.value[item.company_id]) {
    store.dispatch('fetchFreightCharges', { id: item.company_id })
  }
}

const isInGrid = (item) => {
  return item.type.type === 'Grower' && item.extra?.ingrid === true
    ? true
    : false
}

const perPage = computed({
  get: () => perPageSuppliers.value,
  set: (value) => {
    store.commit('SET_PER_PAGE_SUPPLIERS', value)
    store.dispatch('fetchSuppliers')
  }
})

const updatePageNr = (nr) => {
  store.commit('SET_PAGE_NR_SUPP', nr - 1)
  store.dispatch('fetchSuppliers')
}

// const deleteSupp = async (info) => {
//   let res = await store.dispatch('deleteSupplier', { payload: info })
//   if (res === 'success') {
//     store.dispatch('fetchSuppliers')
//   }
// }

// ON CREATED
store.commit('SET_USING_VUETIFY', true)
store.commit('SET_ACTIVE', SUPPLIERS)

if (customerNames.value.length === 0 && loadingCustomers.value !== 'names')
  store.dispatch('fetchCustomerNames')
store.dispatch('fetchSupplierTypes')
</script>
<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <SuppliersFilter />
        <div class="content-page mt-0">
          <v-row no-gutters class="my-2 align-center">
            <div class="page-title-box d-flex">
              <h4 class="page-title">Suppliers</h4>
            </div>
          </v-row>
          <v-row class="mt-1 mb-4 align-center" no-gutters>
            <v-btn
              @click="suppModal.add(), (modalAction = 'add')"
              class="me-2"
              color="primary"
            >
              <v-icon left>mdi-plus</v-icon>New Supplier
            </v-btn>

            <div class="d-flex align-center">
              Total Suppliers:
              <span class="font-weight-bold ms-2">{{ totalSuppliers }}</span>
            </div>
            <div class="ms-auto me-2 d-flex align-center">
              Suppliers per page:
            </div>
            <b-select
              v-model="perPage"
              :options="perPageOptions"
              class="d-inline-block w-auto mr-4"
            ></b-select>
            <PaginationBase
              :total="totalSuppliers"
              :perPage="perPageSuppliers"
              :current="pageNumberSupp + 1"
              @updatePage="updatePageNr"
            />
          </v-row>

          <!-- ------------ Modals --------------- -->
          <NewSupplierModal :type="modalAction" ref="suppModal" />

          <v-card>
            <v-data-table
              id="supplier-table"
              :headers="headers"
              :items="suppliers"
              item-key="id"
              :sort-by="sortbySupp"
              :sort-desc="!ascSupp"
              @update:options="sortChange"
              class="elevation-1"
              :height="suppliers.length > 15 ? 800 : ''"
              :fixed-header="suppliers.length > 15"
              :loading="loadingSuppliers === 'list'"
              loading-text="Loading... Please wait"
              :server-items-length="perPageSuppliers"
              hide-default-footer
              show-expand
              :expanded.sync="expanded"
            >
              <template #item.name="{ item }">
                <div class="my-1 text-body-1">
                  {{ item.company.name }}
                </div>
                <v-row no-gutters v-if="item.company.extra" class="flex-column"
                  ><small
                    class="font-italic"
                    v-for="item in item.company.extra.alias"
                    :key="item.id"
                    ><b class="me-2 text-muted">Alias:</b>{{ item }}</small
                  ></v-row
                >
              </template>
              <template #item.address="{ item }">
                <span v-if="item.company?.address?.postaddress">
                  {{ item.company.address.postaddress }}
                </span>
              </template>
              <template #item.phone="{ item }">
                <span v-if="item.company?.contact?.phone">
                  {{ item.company.contact.phone }}
                </span>
              </template>
              <template #item.abn="{ item }">
                <span class="text-nowrap">{{ item.company.abn }}</span>
              </template>
              <template #item.type="{ item }">
                <v-row no-gutters class="flex-column">
                  <span class="d-block text-nowrap">
                    <font-awesome-icon
                      v-if="item.type.type === 'Grower'"
                      icon="fas fa-tractor"
                      size="sm"
                      class="me-2 text-success"
                    />
                    <font-awesome-icon
                      v-if="item.type.type === 'Freight'"
                      icon="fas fa-truck-arrow-right"
                      size="sm"
                      class="me-2 text-info"
                    />
                    <font-awesome-icon
                      v-if="item.type.type === 'Boning'"
                      icon="fas fa-bone"
                      size="sm"
                      class="me-2 text-danger"
                    />
                    <font-awesome-icon
                      v-if="item.type.type === 'Storage'"
                      icon="fas fa-box-open"
                      size="sm"
                      class="me-2 text-warning"
                    />{{ item.type.type }}
                  </span>
                  <span class="d-block text-nowrap" v-if="isInGrid(item)"
                    ><font-awesome-icon
                      :icon="['fas', 'hand-holding-usd']"
                      class="me-2 text-warning"
                    />On Grid</span
                  >
                </v-row>
              </template>
              <template #item.data-table-expand="{ isExpanded, expand, item }">
                <!-- Hide delete button due to object relationship protection -->
                <!-- <IconDelete @click.native="deleteSupp(item)" class="me-2" /> -->
                <span class="text-nowrap">
                  <iconDollar
                    v-if="item.type.type === 'Freight'"
                    @click.native="
                      () => fetchCharges({ isExpanded, expand, item })
                    "
                    :title="`${isExpanded ? 'Hide' : 'Show'} Charges`"
                    class="me-2" />
                  <iconDollar
                    v-if="item.type.type === 'Grower'"
                    @click.native="() => expand(!isExpanded)"
                    :title="`${isExpanded ? 'Hide' : 'Show'} Pricing`"
                    class="me-2" />
                  <IconEdit
                    @click.native="
                      () => {
                        modalAction = 'edit'
                        suppModal.editSupplier(item)
                      }
                    "
                /></span>
              </template>
              <template #expanded-item="{ item }">
                <td :colspan="headers.length + 1" class="pa-4">
                  <GrowerPricing
                    v-if="item.type.type == 'Grower'"
                    :grower="item"
                  />
                  <FreightCharges
                    :item-details="item"
                    v-if="item.type.type == 'Freight'"
                  />
                </td>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>
