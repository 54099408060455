<template>
  <b-modal
    :id="modalId"
    :title="actionString"
    hide-footer
    :size="usePenalty ? 'lg' : 'md'"
  >
    <b-form @submit="onSubmit" @reset="onReset">
      <label for="price-date"
        >Effective Date:<span class="text-danger">*</span></label
      >
      <b-datepicker
        id="price-date"
        v-model="price.effective_date"
        class="mb-3"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }"
        locale="en-Au"
        start-weekday="1"
        required
      ></b-datepicker>

      <label for="price-cat">Category:<span class="text-danger">*</span></label>
      <b-select
        class="mb-3"
        id="price-cat"
        v-model="price.category"
        :options="priceCategories"
        :disabled="type != 'add'"
      ></b-select>

      <label for="price-value">Price:<span class="text-danger">*</span></label>
      <b-input
        class="mb-3"
        type="number"
        id="price-value"
        step="0.01"
        v-model="price.value"
      ></b-input>

      <b-row v-if="isGrowerParPrice" no-gutters class="mb-3">
        <b-checkbox
          id="price-use-penalty-checkbox"
          v-model="usePenalty"
          :value="true"
          :unchecked-value="false"
          @change="togglePenaltyRates($event)"
          >Enable penalty rates for {{ code }}</b-checkbox
        >
        <b-link
          v-if="usePenalty"
          href="#"
          class="ml-auto"
          @click="addPenaltyRate"
          >+ Add new penalty rate</b-link
        >
      </b-row>
      <div v-if="usePenalty">
        <b-row
          class="flex-row mb-2 penalty-row"
          no-gutters
          v-for="(penalty, key) in price.penaltyrules"
          :key="key"
        >
          <b-col cols="3" class="mr-2">
            <label :for="`price-penalty-p2-${key}`"
              >P2:<span class="text-danger">*</span></label
            >
            <b-input
              type="number"
              :id="`price-penalty-p2-${key}`"
              step="1"
              v-model="penalty.p2.start"
            ></b-input>
          </b-col>

          <b-col cols="3" class="mr-2">
            <label :for="`price-penalty-weight-${key}`"
              >Starting Weight:<span class="text-danger">*</span></label
            ><b-input-group append="kg" class="">
              <b-input
                type="number"
                :id="`price-penalty-weight-${key}`"
                step="0.01"
                v-model="penalty.weight.start"
              ></b-input>
            </b-input-group>
          </b-col>

          <b-col cols="4" class="mr-2">
            <label :for="`price-penalty-value-${key}`"
              >Value:<span class="text-danger">*</span></label
            >
            <b-input-group prepend="$">
              <b-input
                type="number"
                :id="`price-penalty-value-${key}`"
                step="0.01"
                v-model="penalty.value"
              ></b-input></b-input-group
          ></b-col>

          <b-col class="col-auto d-flex ml-auto align-items-end"
            ><div class="pointer hover-red" @click="removePenalty(key)">
              <b>- Remove</b>
            </div></b-col
          ></b-row
        >
      </div>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingSettings == 'par-price'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { getCurrentDay } from '@/utils/DateHelpers'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewParPriceModal',
  props: {
    type: String,
    grower: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      price: {
        category: '',
        effective_date: '',
        value: 0,
        applied_to: 'Default',
        penaltyrules: []
      },
      emptyRate: {
        p2: {
          start: 0,
          end: 1000
        },
        weight: {
          start: 0,
          end: 1000
        },
        value: 4
      },
      priceCategories: ['default', 'SUK', 'SP', 'BP'],
      tempInfo: null,
      usePenalty: false
    }
  },
  computed: {
    ...mapGetters(['loadingSettings']),
    modalId() {
      if (this.grower) {
        return `price-modal-${this.grower.id}`
      }
      return 'price-modal'
    },
    actionString() {
      const growerString =
        this.price.applied_to !== 'Default' ? ' - ' + this.grower.code : ''
      if (this.type == 'add') return `Create PAR Price${growerString}`

      return `Update PAR Price - ${this.price.category}${growerString}`
    },
    isGrowerParPrice() {
      if (this.price.applied_to !== 'Default') return true
      return false
    },
    code() {
      if (this.grower) return this.grower.code
      return null
    }
  },
  methods: {
    ...mapActions([
      'createParPrice',
      'fetchParPrice',
      'updateParPrice',
      'fetchGrowerParPrice'
    ]),
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      if (!this.usePenalty) this.price.penaltyrules = []
      if (this.type == 'add') {
        res = await this.createParPrice({
          payload: this.price,
          vm: this
        })
      }
      if (this.type == 'edit') {
        res = await this.updateParPrice({
          payload: this.price,
          vm: this
        })
      }
      if (res === 'error') return

      this.$bvModal.hide(this.modalId)
      this.fetchPrice()
    },
    fetchPrice: function () {
      if (this.grower) {
        this.fetchGrowerParPrice({
          growerId: String(this.grower.company_id)
        })
      } else {
        this.fetchParPrice({ vm: this })
      }
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: async function () {
      await this.resetValues()
      if (this.grower) {
        this.price['applied_to'] = String(this.grower.company_id)
      }
      this.$bvModal.show(this.modalId)
    },
    edit: function (info) {
      this.tempInfo = info
      this.price.id = this.tempInfo.id
      this.restoreValues()
      this.$bvModal.show(this.modalId)
    },
    // Reset price form values
    resetValues: async function () {
      if (Object.prototype.hasOwnProperty.call(this.price, 'id'))
        delete this.price.id
      this.usePenalty = false
      this.price.effective_date = getCurrentDay()
      this.price.category = 'default'
      this.price.applied_to = 'Default'
      this.price.value = 0
      this.price.penaltyrules = []
    },
    // Restore price form values to initial
    restoreValues: function () {
      this.price.effective_date = this.tempInfo.effective_date
      this.price.category = this.tempInfo.category
      this.price.applied_to = this.tempInfo.applied_to
      this.price.value = this.tempInfo.value
      this.price.penaltyrules = JSON.parse(
        JSON.stringify(this.tempInfo.penaltyrules)
      )
      if (!this.grower) return
      this.price.penaltyrules.length > 0
        ? (this.usePenalty = true)
        : (this.usePenalty = false)
    },
    togglePenaltyRates: function (el) {
      if (el) {
        if (this.price.penaltyrules.length === 0) {
          this.addPenaltyRate()
        }
      }
    },
    addPenaltyRate: function () {
      let rule = JSON.parse(JSON.stringify(this.emptyRate))
      this.price.penaltyrules.push(rule)
    },
    removePenalty: function (id) {
      this.price.penaltyrules.splice(id, 1)
    }
  }
}
</script>

<style scoped>
.penalty-row:not(:last-child) {
  border-bottom: 1px #ced4da solid;
  padding-bottom: 1rem;
}
</style>
