<script setup>
import { useStore } from '@/utils/VuexHelpers'
import BaseConfirmDialog from '@/components/base/BaseConfirmDialog.vue'
import { defineProps, computed } from 'vue'

const store = useStore()
const props = defineProps(['action', 'grower'])

const inGrid = computed(() => {
  return props.action === 'DISABLE' ? false : true
})

const toggleGridOption = () => {
  const payload = {
    extra: { ingrid: inGrid.value },
    id: props.grower.id
  }
  store.dispatch('updateSupplier', {
    payload,
    onSuccess: 'replace'
  })
}
</script>

<template>
  <BaseConfirmDialog
    size="small"
    :text="inGrid"
    :button-icon="inGrid ? 'mdi-check' : 'mdi-close'"
    :button-text="`${action} Grid Pricing`"
    :button-color="inGrid ? 'success' : 'blue-grey lighten-3'"
    :button-text-class="inGrid ? 'text-decoration-underline' : ''"
    :title="`Are you sure you want to ${action} Grower Grid pricing?`"
    @confirm="toggleGridOption()"
  >
    <template #content>
      <div class="text-subtitle-2 mt-2">
        Grower:
        <span class="font-weight-bold">
          {{ grower.company.name }}
        </span>
      </div>
    </template>
  </BaseConfirmDialog>
</template>
