<script setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Are you sure you want to delete?'
  },
  buttonText: {
    type: String,
    default: 'Delete'
  },
  icon: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  }
})
defineEmits(['deleteConfirm'])

const dialog = ref(false)

const closeDialog = () => {
  dialog.value = false
}

defineExpose({ closeDialog })
</script>

<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :x-small="size === 'x-small'"
          :small="size === 'small'"
          :text="true"
          :icon="icon"
          class="my-1"
          color="red"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          <font-awesome-icon
            icon="fa-regular fa-trash-can"
            :class="{ 'mr-2': !icon }"
          /><span v-if="!icon">{{ buttonText }}</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h6 red lighten-3">
          <slot name="title">{{ title }}</slot>
        </v-card-title>

        <v-card-text class="text-start">
          <slot name="content">This action is irreversible!</slot>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text small @click="closeDialog"> Cancel </v-btn>
          <v-btn
            color="primary"
            small
            @click="$emit('deleteConfirm')"
            :loading="loading"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
