<script setup>
import IconEdit from '../IconEdit.vue'
import NewFreightChargesModal from '../NewFreightChargesModal.vue'
import BaseDeleteConfirm from '../base/BaseDeleteConfirm.vue'

import { useStore } from '@/utils/VuexHelpers'
import { computed, ref, defineProps } from 'vue'
import { formatDate, formatCurrency } from '@/helpers/stringHelpers'

defineProps(['itemDetails'])
const chargesModal = ref(null)

const store = useStore()
const loadingSettings = computed(() => store.getters.loadingSettings)
const freightCharges = computed(() => store.getters.freightCharges)
const customerCodeMap = computed(() => store.getters.customerCodeMap)

const chargesAction = ref('add')

const getCustomerCode = (value) => {
  if (value.search('Default') !== -1) return value

  let arr = value.split(',')
  const mapped = arr.map((el) => customerCodeMap.value[el].code)
  return mapped.join('/')
}

const deleteCharge = async (info) => {
  let res = await store.dispatch('deleteFreightCharges', info)
  if (res === 'success') {
    store.dispatch('fetchFreightCharges', {
      id: info.freight_company_id
    })
  }
}
</script>

<template>
  <div
    v-if="loadingSettings === `charges-${itemDetails.company_id}`"
    class="text-center text-danger my-2"
  >
    <b-spinner small class="align-middle me-2"></b-spinner>
    <strong>Loading Freight Charges...</strong>
  </div>

  <div v-else>
    <NewFreightChargesModal
      ref="chargesModal"
      :type="chargesAction"
      :companyId="itemDetails.company_id"
    />

    <v-row no-gutters class="justify-start my-2"
      ><v-btn
        color="primary"
        small
        text
        @click="
          () => {
            chargesAction = 'add'
            chargesModal.add(itemDetails)
          }
        "
        ><v-icon left>mdi-plus</v-icon>Add new charges</v-btn
      ></v-row
    >
    <v-row no-gutters>
      <b-table-simple small hover responsive class="text-left">
        <b-thead>
          <b-tr>
            <b-th class="bg-light-blue">Effective Date</b-th>
            <b-th class="bg-light-blue">$/kg (excl gst)</b-th>
            <b-th class="bg-light-blue">Min Charge</b-th>
            <b-th class="bg-light-blue">Fuel Levy</b-th>
            <b-th class="bg-light-blue">$/Pallet (excl gst)</b-th>
            <b-th class="bg-light-blue">Notes</b-th>
            <b-th class="bg-light-blue">Aplied to</b-th>
            <b-th class="bg-light-blue"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="charge in freightCharges[itemDetails.company_id]"
            :key="charge.id"
          >
            <b-td class="v-align-center">{{
              formatDate(charge.effective_date)
            }}</b-td>
            <b-td class="v-align-center">{{
              formatCurrency(charge.price_kg)
            }}</b-td>
            <b-td class="v-align-center">{{
              formatCurrency(charge.min_charge)
            }}</b-td>
            <b-td class="v-align-center">{{
              formatCurrency(charge.fuel_levy)
            }}</b-td>
            <b-td class="v-align-center">{{
              formatCurrency(charge.price_pallet)
            }}</b-td>
            <b-td class="v-align-center">{{
              charge.notes ? charge.notes : '-'
            }}</b-td>
            <b-td class="v-align-center">{{
              getCustomerCode(charge.applied_to)
            }}</b-td>
            <b-td>
              <v-row no-gutters class="align-center">
                <BaseDeleteConfirm
                  :icon="true"
                  size="small"
                  title="Are you sure you want to delete freight charges?"
                  class="me-1"
                  @deleteConfirm="deleteCharge(charge)"
                >
                  <template #content>
                    <div class="text-subtitle-2 mt-2">
                      Freight Company:
                      <span class="font-weight-bold">
                        {{ itemDetails.company.name }}
                      </span>
                    </div>
                    <div class="text-subtitle-2 mt-2">
                      Effective Date:
                      <span class="font-weight-bold">
                        {{ formatDate(charge.effective_date) }}
                      </span>
                    </div>
                    <div class="text-subtitle-2 mt-2">
                      Applied To:
                      <span class="font-weight-bold">
                        {{ getCustomerCode(charge.applied_to) }}
                      </span>
                    </div>
                  </template>
                </BaseDeleteConfirm>
                <IconEdit
                  :position="'bottom'"
                  @click.native="
                    () => {
                      chargesAction = 'edit'
                      chargesModal.edit(charge)
                    }
                  " /></v-row
            ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </v-row>
  </div>
</template>
