<script setup>
import NewParPriceModal from '@/components/NewParPriceModal.vue'
import GrowerPricingPARDelete from './GrowerPricingPARDelete.vue'
import GrowerPricingDuplicateDialog from './GrowerPricingDuplicateDialog.vue'
import IconEdit from '@/components/IconEdit.vue'
import GridToggle from './GridToggle.vue'

import { computed, onMounted, defineProps, ref } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import {
  formatCurrency,
  formatDate,
  formatKilograms
} from '@/helpers/stringHelpers'

const props = defineProps({
  grower: {
    type: Object,
    required: true
  }
})
const parPriceModal = ref(null)

const store = useStore()
const loadingSettings = computed(() => store.getters.loadingSettings)
const loadingSuppliers = computed(() => store.getters.loadingSuppliers)
const growerParPrice = computed(() => store.getters.growerParPrice)

const pricingAction = ref('add')

const isInGrid = computed(() => {
  return props.grower.extra?.ingrid === true
})

onMounted(() => {
  if (
    !growerParPrice.value[props.grower.company_id] &&
    loadingSettings.value !== `par-price-${props.grower.company_id}`
  ) {
    store.dispatch('fetchGrowerParPrice', {
      growerId: String(props.grower.company_id)
    })
  }
})
</script>
<template>
  <div
    v-if="
      loadingSettings === `par-price-${grower.company_id}` ||
      loadingSuppliers === `update-${grower.id}`
    "
    class="text-center text-danger my-2"
  >
    <b-spinner small class="align-middle me-2"></b-spinner>
    <strong>Loading Grower Pricing...</strong>
  </div>
  <div v-else>
    <NewParPriceModal
      ref="parPriceModal"
      :type="pricingAction"
      :grower="grower"
    />
    <v-row
      class="justify-start align-center flex-column my-5"
      no-gutters
      v-if="isInGrid"
    >
      <span class="mb-3"
        ><span class="font-weight-bold">{{ grower.company.name }}</span> is
        using pricing from Grower Grid</span
      >
      <GridToggle action="DISABLE" :grower="grower" />
    </v-row>
    <div v-else>
      <v-row no-gutters class="justify-space-between my-2"
        ><v-btn
          color="primary"
          small
          text
          @click="
            () => {
              pricingAction = 'add'
              parPriceModal.add()
            }
          "
          ><v-icon left>mdi-plus</v-icon>Add PAR Price</v-btn
        >
        <GridToggle action="ENABLE" :grower="grower" />
      </v-row>
      <b-table-simple
        :id="`grow-par-price-${grower.code}`"
        small
        responsive
        striped
        class="text-left"
      >
        <b-thead>
          <b-tr class="bg-light-blue">
            <b-th class="v-align-center bg-light-blue">Effective Date</b-th>
            <b-th class="v-align-center bg-light-blue">Category</b-th>
            <b-th class="v-align-center bg-light-blue">Applied To</b-th>
            <b-th class="v-align-center bg-light-blue">Penalty Rates</b-th>
            <b-th class="v-align-center bg-light-blue">Price</b-th>
            <b-th class="v-align-center bg-light-blue">Actions</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="loadingSettings === `par-price-${grower.company_id}`">
          <b-tr>
            <b-td colspan="8"
              ><div class="text-center text-danger my-2">
                <b-spinner small class="align-middle me-2"></b-spinner>
                <strong>Loading...</strong>
              </div></b-td
            ></b-tr
          >
        </b-tbody>
        <b-tbody v-else>
          <b-tr
            v-for="(priceDetails, key) in growerParPrice[grower.company_id]"
            :key="key"
          >
            <b-td>{{ formatDate(priceDetails.effective_date) }}</b-td>
            <b-td>{{ priceDetails.category }}</b-td>
            <b-td>{{
              priceDetails.applied_to == 'Default' ? 'All' : grower.code
            }}</b-td>
            <b-td v-if="priceDetails.penaltyrules.length === 0">-</b-td>
            <b-td v-else>
              <div class="grid-container">
                <template v-for="(rate, idx) in priceDetails.penaltyrules">
                  <div :key="`${idx}-p2-${rate.p2.start}`">
                    <span class="text-muted">P2: </span>
                    {{
                      `${rate.p2.start} - ${
                        rate.p2.end > 30 ? 'MAX' : rate.p2.end
                      }`
                    }}
                  </div>
                  <div :key="`${idx}-weight-${rate.weight.start}`">
                    <span class="text-muted">Weight: </span>
                    {{
                      `${formatKilograms(rate.weight.start)} - ${
                        rate.weight.end > 105
                          ? 'MAX'
                          : formatKilograms(rate.weight.end)
                      }`
                    }}
                  </div>
                  <div :key="`${idx}-price-${rate.value}`">
                    <span class="text-muted">Penalty: </span>
                    {{ `${formatCurrency(rate.value)}` }}
                  </div>
                  <div
                    v-if="idx !== priceDetails.penaltyrules.length - 1"
                    :key="`${idx}-divider`"
                    class="divider"
                  ></div>
                </template>
              </div>
            </b-td>
            <b-td>{{ formatCurrency(priceDetails.value) }}</b-td>
            <b-td class="py-0">
              <v-row no-gutters class="align-center">
                <GrowerPricingPARDelete
                  :grower-details="grower"
                  :price-details="priceDetails"
                />
                <IconEdit
                  :position="'bottom'"
                  class="me-1"
                  @click.native="
                    () => {
                      pricingAction = 'edit'
                      parPriceModal.edit(priceDetails)
                    }
                  "
                />
                <GrowerPricingDuplicateDialog
                  v-if="priceDetails.penaltyrules.length > 0"
                  :price-details="priceDetails"
                  :grower-details="grower"
                />
              </v-row>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<style scoped>
.penalty-row:not(:last-child) {
  border-bottom: 1px #ced4da solid;
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
}

.grid-container {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  gap: 6px;
}

.grid-container > div {
  text-align: start;
  margin-right: 2rem;
}

.divider {
  grid-column: 1 / -1; /* Span the divider across all columns */
  height: 1px;
  background-color: #ccc;
}
</style>
