<script setup>
import OrderItemsTable from '@/components/orders-page/OrderItemsTable.vue'
import OrderFormDialog from '@/components/orders-page/OrderFormDialog.vue'
import BaseDeleteConfirm from '../base/BaseDeleteConfirm.vue'

import { computed, defineProps } from 'vue'
import { formatDate } from '@/helpers/stringHelpers.js'
import { useStore } from '@/utils/VuexHelpers'

defineProps(['orderDetails'])

const store = useStore()
const loadingOrders = computed(() => store.getters.loadingOrders)

const deleteItem = async ({ order, info }) => {
  let res = await store.dispatch('deleteOrderItem', { payload: info })
  if (res === 'success') {
    order.ordered_items = order.ordered_items.filter((el) => {
      return el.id != info.id
    })
  }
}

const deleteOrder = async (info) => {
  let res = await store.dispatch('deleteOrder', { payload: info })
  if (res === 'success') {
    store.dispatch('fetchOrders')
  }
}
</script>

<template>
  <v-card outlined :loading="loadingOrders === `update-${orderDetails.id}`">
    <v-card-title>
      <h6 class="text-h6 mb-0">
        {{ `${orderDetails.customer.company.name} -` }}
        <span class="font-weight-bold">
          {{ orderDetails.customer.code }}
        </span>
      </h6>
      <b-col cols="auto" class="ml-auto pa-0 d-flex align-center">
        <OrderFormDialog :edit="true" :orderDetails="orderDetails" />
        <BaseDeleteConfirm
          size="small"
          title="Are you sure you want to delete the order?"
          @deleteConfirm="deleteOrder(orderDetails)"
          :loading="loadingOrders === 'delete'"
          ><template v-slot:content>
            <div class="mt-4">
              Customer:
              <span class="font-weight-bold">{{
                orderDetails.customer.company.name
              }}</span>
            </div>
            <div>
              Processing Day:
              <span class="font-weight-bold">{{
                formatDate(orderDetails.date)
              }}</span>
            </div></template
          ></BaseDeleteConfirm
        >
      </b-col>
    </v-card-title>
    <v-card-subtitle
      ><v-row no-gutters class="align-baseline"
        ><h6 class="mr-1 mb-0 text-light-blue text-button">Processing Day:</h6>
        <span class="font-weight-medium">{{
          formatDate(orderDetails.date)
        }}</span></v-row
      ></v-card-subtitle
    >
    <v-card-text>
      <v-row no-gutters class="mt-2 text-left">
        <OrderItemsTable
          :order="orderDetails"
          @delete="deleteItem({ order: orderDetails, info: $event })"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>
