import axios from 'axios'
import Vue from 'vue'

import { PER_PAGE_OPTIONS, PER_PAGE_MAX } from '@/constants/pagination'

const state = {
  settings: null,
  loadingSettings: '',
  freightCharges: [],
  parPrice: [],
  growerParPrice: [],

  //pagination
  totalSett: 0,
  perPageSett: PER_PAGE_OPTIONS[0],
  pageNrSett: 0,

  //sorting
  sortbySett: 'effective_date',
  ascSett: true,

  //filters
  filtersCharges: {
    freight_company_id: { type: 'text', label: 'Freight Company', value: '' }
  }
}
const getters = {
  settings: (state) => state.settings,
  freightCharges: (state) => state.freightCharges,
  parPrice: (state) => state.parPrice,
  growerParPrice: (state) => state.growerParPrice,

  loadingSettings: (state) => state.loadingSettings,
  totalSett: (state) => state.totalSett,
  perPageSett: (state) => state.perPageSett,
  pageNumberSett: (state) => state.pageNrSett,
  sortbySett: (state) => state.sortbySett,
  ascSett: (state) => state.ascSett,
  offsetSett: (state) => {
    return state.perPageSett * state.pageNrSett
  },
  filtersCharges: (state) => state.filtersCharges
}
const actions = {
  async fetchParPrice({ commit, dispatch }, { vm }) {
    commit('SET_LOADING_SETTINGS', 'par-price')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'systemsettings/parprice',
        parameters: {
          limit: PER_PAGE_MAX,
          offset: 0,
          sort_by: '',
          ascending: false
        },
        filters: { applied_to: { type: 'text', value: 'Default' } }
      },
      { root: true }
    )
    commit('SET_LOADING_SETTINGS', '')
    if (!res.error) {
      commit('SET_PAR_PRICE', res.data)
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'PAR Price',
          messagePrefix: 'Error while loading PAR price! '
        },
        { root: true }
      )
    }
  },
  async fetchGrowerParPrice({ commit, dispatch }, { growerId }) {
    commit('SET_LOADING_SETTINGS', `par-price-${growerId}`)
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'systemsettings/parprice',
        parameters: {
          limit: PER_PAGE_MAX,
          offset: 0,
          sort_by: '',
          ascending: false
        },
        filters: { applied_to: { type: 'text', value: growerId } }
      },
      { root: true }
    )
    commit('SET_LOADING_SETTINGS', '')
    if (!res.error) {
      commit('SET_GROWER_PAR_PRICE', { data: res.data, id: growerId })
    } else {
      const messagePrefix = 'Error while loading PAR price! '
      const { error } = res
      dispatch('showError', { error, messagePrefix })
    }
  },
  async createParPrice({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'systemsettings/parprice'
    try {
      commit('SET_LOADING_SETTINGS', `par-price`)
      await axios.post(URI, payload)

      const message = 'New PAR price created successfuly! '
      if (rootGetters.usingVuetify) {
        dispatch('showMessage', message)
      } else {
        vm.$bvToast.toast(message, {
          title: 'Create PAR Price',
          variant: 'success',
          solid: true
        })
      }

      return 'success'
    } catch (error) {
      const messagePrefix = 'Create PAR Price Error'
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        await dispatch(
          'handleError',
          { vm, error, title: messagePrefix },
          { root: true }
        )
      }
      return 'error'
    } finally {
      commit('SET_LOADING_SETTINGS', '')
    }
  },
  async updateParPrice({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'systemsettings/parprice/' + payload.id
    try {
      commit('SET_LOADING_SETTINGS', `par-price`)
      await axios.patch(URI, payload)

      const message = 'PAR price updated successfuly! '
      if (rootGetters.usingVuetify) {
        dispatch('showMessage', message)
      } else {
        vm.$bvToast.toast(message, {
          title: 'Update PAR Price',
          variant: 'success',
          solid: true
        })
      }
      return 'success'
    } catch (error) {
      const messagePrefix = 'Update PAR Price Error! '
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        await dispatch(
          'handleError',
          { vm, error, title: messagePrefix },
          { root: true }
        )
      }
      return 'error'
    } finally {
      commit('SET_LOADING_SETTINGS', '')
    }
  },
  async deleteParPrice({ rootGetters, commit, dispatch }, { payload, vm }) {
    const { id, effective_date } = payload
    const URI = rootGetters.baseUrl + 'systemsettings/' + id

    try {
      commit('SET_LOADING_SETTINGS', `par-price-delete`)
      await axios.delete(URI)

      const message = `PAR price deleted successfuly! ${effective_date}`
      if (rootGetters.usingVuetify) {
        dispatch('showMessage', message)
      } else {
        vm.$bvToast.toast(message, {
          title: 'Delete PAR Price',
          variant: 'success',
          solid: true
        })
      }

      return 'success'
    } catch (error) {
      const messagePrefix = 'Delete PAR Price Error! '
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        await dispatch(
          'handleError',
          { vm, error, title: messagePrefix },
          { root: true }
        )
      }
      return 'error'
    } finally {
      commit('SET_LOADING_SETTINGS', '')
    }
  },
  async fetchFreightCharges({ commit, getters, dispatch }, { id }) {
    commit('SET_LOADING_SETTINGS', `charges-${id}`)
    commit('SET_FILTER_CHARGES', {
      field: 'freight_company_id',
      value: `${id}`
    })
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'systemsettings/freightcharge',
        parameters: {
          limit: getters.perPageSett,
          offset: getters.offsetSett,
          sort_by: getters.sortbySett,
          ascending: getters.ascSett
        },
        filters: getters.filtersCharges
      },
      { root: true }
    )
    commit('SET_LOADING_SETTINGS', '')
    if (!res.error) {
      commit('SET_FREIGHT_CHARGES', { data: res.data, id: id })
    } else {
      const messagePrefix = 'Error while loading Freight Charges! '
      const { error } = res
      dispatch('showError', { error, messagePrefix })
    }
  },
  async createFreightCharges({ rootGetters, commit, dispatch }, payload) {
    const URI = rootGetters.baseUrl + 'systemsettings/freightcharge'
    try {
      commit('SET_LOADING_SETTINGS', `charges-${payload.freight_company_id}`)

      await axios.post(URI, payload)
      dispatch('showMessage', 'New Freight charges created successfuly! ')

      return 'success'
    } catch (error) {
      const messagePrefix = 'Create Freight Charges Error'
      dispatch('showError', { error, messagePrefix })
      return 'error'
    } finally {
      commit('SET_LOADING_SETTINGS', '')
    }
  },
  async updateFreightCharges({ rootGetters, commit, dispatch }, payload) {
    const URI =
      rootGetters.baseUrl + 'systemsettings/freightcharge/' + payload.id
    try {
      commit('SET_LOADING_SETTINGS', `charges-${payload.freight_company_id}`)

      await axios.patch(URI, payload)
      dispatch('showMessage', 'Freight charge information updated successfuly!')

      return 'success'
    } catch (error) {
      const messagePrefix = 'Update Freight Charges Error'
      dispatch('showError', { error, messagePrefix })
      return 'error'
    } finally {
      commit('SET_LOADING_SETTINGS', '')
    }
  },
  async deleteFreightCharges({ rootGetters, commit, dispatch }, payload) {
    const URI = rootGetters.baseUrl + 'systemsettings/' + payload.id
    try {
      commit('SET_LOADING_SETTINGS', `charges-${payload.freight_company_id}`)
      await axios.delete(URI)

      const message = `Freight charges deleted successfuly! ${payload.effective_date}`
      dispatch('showMessage', message)

      return 'success'
    } catch (error) {
      const messagePrefix = 'Delete Freight Charges Error'
      dispatch('showError', { error, messagePrefix })
      return 'error'
    } finally {
      commit('SET_LOADING_SETTINGS', '')
    }
  }
}
const mutations = {
  SET_FREIGHT_CHARGES: (state, payload) => {
    state.freightCharges[payload.id] = payload.data
  },
  SET_PAR_PRICE: (state, payload) => {
    state.parPrice = payload
  },
  SET_GROWER_PAR_PRICE: (state, { data, id }) => {
    Vue.set(state.growerParPrice, id, data)
  },
  SET_LOADING_SETTINGS: (state, payload) => (state.loadingSettings = payload),
  SET_TOTAL_SETT: (state, payload) => (state.totalSett = payload),
  SET_PAGE_NR_SETT: (state, payload) => (state.pageNrSett = payload),
  SET_PER_PAGE_SETT: (state, payload) => (state.perPageSett = payload),
  SET_SORT_BY_SETT: (state, payload) => (state.sortbySett = payload),
  SET_ASC_SETT: (state, payload) => (state.ascSett = payload),
  SET_FILTER_CHARGES: (state, payload) => {
    state.filtersCharges[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
