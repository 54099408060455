import { render, staticRenderFns } from "./BaseConfirmDialog.vue?vue&type=template&id=02d3b68a&scoped=true"
import script from "./BaseConfirmDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseConfirmDialog.vue?vue&type=script&setup=true&lang=js"
import style0 from "./BaseConfirmDialog.vue?vue&type=style&index=0&id=02d3b68a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02d3b68a",
  null
  
)

export default component.exports